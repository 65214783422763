import { combineReducers } from 'redux';
import { companyReducer } from './company';
import { financialYearReducer } from './fy';
import { clientReducer } from './client';
import { userReducer } from './user';
import { QueriesReducer } from './queries';
import { fileReducer } from './files';

export default combineReducers({
    user: userReducer,
    company: companyReducer,
    client: clientReducer,
    fy: financialYearReducer,
    queries: QueriesReducer,
    file: fileReducer,
});
