import { Card, Table, Button, Radio, Modal, Tabs, Typography } from "antd";
import { Search_box } from '../components/Search_box/search_box';
import React, { useEffect, useState } from "react";
import { Loader } from "../components/loader";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedClient, getClients } from "../store/action/client";
import { changeSelectedFY, getFinancialYears } from "../store/action/fy";
import { useLocation, useNavigate, useParams, useRoutes, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { BsPlus } from 'react-icons/bs';
import useFetch from '../_fetch';
import CreateQuery from "./createQuery";
import { getQueries } from "../store/action/queries";
import Query from "./Query";
import Fyassign from '../components//querySidebar/fyAsing'
import CommentView from "../components/commentView";
import { changeSelectedCompany } from '../store/action/company'
function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AllQueries() {
	const [modalQueryId, setModalQueryId] = useState(null);
	const [show, setShow] = useState([]);
	const [comments, setComments] = useState([]);
	let dispatch = useDispatch();
	let navigate = useNavigate();
	const [updatedquery, setUpdatedquery] = useState([])

	const [loading, setLoading] = useState(true);
	const placeholder = "Search Queries"
	const { selected, all: allFys } = useSelector(st => st.fy);
	const { selected: selectedClient, all: allClients } = useSelector(st => st.client);
	const { selected: selectedCompany } = useSelector(st => st.company);
	const { grouped: fy } = useSelector(st => st.fy);
	const user = useSelector(st => st.user)
	const { queries } = useSelector(st => st.queries);
	const { all: client } = useSelector(st => st.client);
	const [showQuery, setShowQuery] = useState(false);
	const [quer, setQuery] = useState([])
	const [seed, setSeed] = useState(1);
	let query = useQuery();
	const _fetch = useFetch();
	const [cookie, setCookie, removeCookie] = useCookies('userInfo')
	const reset = () => {
		setSeed(Math.random());
	}

	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			width: "20%",
			sorter: (a, b) => { return (`${(a.title).toLowerCase()}` > `${(b.title).toLowerCase()}`) ? 10 : -10 },

		},
		{
			title: "Entity",
			key: "client",
			sorter: (a, b) => {
				const title = (record) => {

					let client = allFys?.find(e => e._id === record.financialYear)?.client_id;
					if (client) {
						client = allClients.find(e => e._id === client);
					}
					return client.title;
				}
				const aa = title(a);
				const bb = title(b);
				return (`${(aa).toLowerCase()}` > `${(bb).toLowerCase()}`) ? 10 : -10

			},

			render: (record) => {
				let client = allFys?.find(e => e._id === record.financialYear)?.client_id;
				if (client) {
					client = allClients.find(e => e._id === client);
				}
				return client?.title;
			}
		},
		{
			title: "Status",
			key: "status",
			dataIndex: "status",
			sorter: (a, b) => { return (`${(a.status).toLowerCase()}` > `${(b.status).toLowerCase()}`) ? 10 : -10 },
			// eslint-disable-next-line array-callback-return
			render(record) {
				return (
					<div style={{ padding: '5px', borderRadius: '10px', textAlign: 'center', backgroundColor: record === "CLOSED" ? "green" : null, color: record === "CLOSED" ? 'white' : '' }}>{record}</div>);

			}
		},
		{
			title: "Created On",

			key: "createdAt",
			sorter: (a, b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime(),
			render: (record) => moment(record.createdAt).format('LL'),

		},
		{
			title: "Query Owner",
			key: "createdBy",
			sorter: (a, b) => { return (`${a.created_by?.first_name} ${a.created_by?.last_name}`.toLowerCase() > `${b.created_by?.first_name} ${b.created_by?.last_name}`.toLowerCase()) ? 10 : -10 },
			render: (record) => `${record.created_by?.first_name} ${record.created_by?.last_name}`,
		},
		{
			title: "",
			key: "actions",
			render: (record) => <Button type="primary" onClick={() => navigate(`/query/${record.query_id}`)}>View Query</Button>,
		}
	];

	const init = async () => {
		try {
			if (query.get('fy')) {
				let commRes = await _fetch(
					`${process.env.REACT_APP_API_URL}/comment?fy=${query.get('fy')}`
				);

				commRes = await commRes.json();

				setComments(commRes.response);
			}

		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected, seed]);

	useEffect(() => {
		setLoading(true);
		async function _getQueries() {
			setLoading(true);
			try {
				let clients;
				if (query.get('company') === selectedCompany._id) {
					if (!(Array.isArray(allClients) && allClients.length)) {

						clients = await dispatch(getClients(selectedCompany._id))
					}

					if (clients && !(Array.isArray(allFys) && allFys.length)) {
						dispatch(getFinancialYears(clients.map(e => e._id)))
					}

					if (query.get('company') && query.get('fy')) {
						let get = await dispatch(getQueries(query.get('company'), query.get('fy')));
						setQuery(get)
						setCookie('userInfo', {
							...cookie.userInfo,
							selectedCompany: selectedCompany
						});
						await dispatch(changeSelectedCompany(selectedCompany))

						let res = fy?.filter(elem => elem.client_id === query.get('entity'))
						let filteredFy = res[0].financial_year.filter(elem => elem._id === query.get('fy'))
						let changedClient = client?.filter(elem => elem._id === query.get('entity'))
						await dispatch(changeSelectedClient(changedClient[0]))
						await dispatch(changeSelectedFY(filteredFy[0]))
					}
				}
				if (selected && !query.get('company') && !query.get('fy')) {
					let get = await dispatch(getQueries(selectedCompany._id, selected._id));
					setQuery(get)
				}
			} catch (e) {
				console.log(e)
			}
			setLoading(false)

		}

		_getQueries();
		setLoading(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCompany, seed, query, fy])


	const handleFyUpdate = async (update) => {

		const payload = {
			...update,
			fy: query.get('fy')
		}
		let updatedQuery = await _fetch(
			`${process.env.REACT_APP_API_URL}/fy`,
			{
				method: 'PATCH',
				body: payload,
			}
		);
		let resJson = await updatedQuery.json();

		if (resJson.status === 200) {
			dispatch(changeSelectedFY(resJson.response));
		}


	};
	const refresh = () => {
		dispatch(getQueries(query.get('company'), query.get('fy')))
		reset()

	}

	return (
		<>
			{
				loading ?
					<Loader />
					:
					<div className="tabled">

						<Modal
							style={{
								top: 0,
							}}
							width="70vw"
							destroyOnClose
							visible={modalQueryId}
							centered
							footer={null}
							onOk={() => setModalQueryId(null)}
							onCancel={() => setModalQueryId(null)}
						>
							<Query query={modalQueryId} change={setUpdatedquery} />

						</Modal>
						<Card
							bordered={false}
							className="circle_box table_space mb-24 w-100"


						>
							<Tabs type='card' tabBarExtraContent={
								<div style={{ "display": "flex", padding: "5px", alignItems: "center" }}>
									{
										query.get('fy') && <Fyassign query={selected}
											className='p-2'
											handleFyUpdate={handleFyUpdate}
											role={""} />
									}
									{user.role === 'COMPANY' ? null : <Button type="link" onClick={() => setShowQuery(true)} >
										<BsPlus size={20} /> Add Query
									</Button>}

								</div>}

							>
								<Tabs.TabPane

									tab={
										<Typography.Title
											level={5}
											className='align-self-start'
										>

											<div className="flex-fill search d-flex">
												{
													query.get('entity') ?
														<span className="me-3">
															Entity: {selectedClient.title}
														</span> : "All Queries"
												}
												<span className="me-3">{query.get('fy') ? `FY : ${moment(selected.start).format('YY')}/${moment(selected.end).format('YY')}` : null}</span>

											</div>
										</Typography.Title>
									}
									key='comment'
								>
									<div className="table-responsive">
										<div className="d-flex flex-fill justify-justify-center" id="fySearch" style={{ padding: "0px 50px" }}>
											<Search_box all={quer} setshow={setShow} name={placeholder} />
										</div>
										<Table
											onRow={(record, rowIndex) => {
												return {
													onClick: event => {
														setModalQueryId(record.query_id);
													}, // click row
												};
											}}
											sortDirections={['ascend', 'descend', 'ascend']}
											columns={columns}
											dataSource={show}
											pagination={false}
											className="ant-border-space"
										// rowSelection={{ type: 'radio' }}
										/>
									</div>

								</Tabs.TabPane>

								{query.get('fy') && <Tabs.TabPane

									tab={
										<Typography.Title
											level={5}
											className='align-self-start'
										>
											Send Email
										</Typography.Title>
									}
									key='comments'
								>

									<div style={{ padding: "0px 20px" }}>
										<CommentView
											comments={comments}
											fy={query.get('fy')}
											// id={() => query._id}
											setComments={setComments}
											data={selected}
										/>
									</div>




								</Tabs.TabPane>}


							</Tabs>


						</Card>
					</div >
			}
			<CreateQuery
				refresh={refresh}
				show={showQuery}
				setShow={setShowQuery}
			/>
		</>
	);
}




