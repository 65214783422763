import { useEffect, useRef, useState } from 'react';

import RQuill from "../components/quill_editor"
// import { uploadFile } from 'react-s3';
// import ReactQuill from 'react-quill';
import { Buffer } from 'buffer';
import { useParams } from 'react-router-dom';
import {
    Card,
    Input,
    Button,
    Typography,
    Row,
    Col,
    Divider,
    Tabs,
    Form,
} from 'antd';
import useFetch from '../_fetch';
import { Loader } from '../components/loader';
import CommentView from '../components/commentView';
import Files from '../components/files';
import { changeQuery } from "../store/action/queries";
import QuerySidebar from '../components/querySidebar';
import { useDispatch, useSelector } from 'react-redux';
import Activity from '../components/activity';
import { user_role } from '../consts';
import moment from 'moment';
var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;

export default function Query(props) {
    const quillContent = useRef({ content: {}, html: "" });

    const [query, setQuery] = useState({});
    const displayDesc = useRef();
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [editQuery, setEditQuery] = useState(false);
    const { role } = useSelector((st) => st.user);
    const _fetch = useFetch();
    const [form] = Form.useForm();
    let { query_id } = useParams();
    if (props.query) {
        query_id = props.query;
    }
    const dispatch = useDispatch();

    // const reset = () => {
    //     setSeed(Math.random());
    // };

    const init = async () => {
        try {
            let res = await _fetch(
                `${process.env.REACT_APP_API_URL}/query/${query_id}`
            );
            let result = await res.json();
            console.log(result)
            if (res.status === 200) {
                console.log(result.response);
                setQuery(result.response);
                if (result.response.descriptionV2.length) {
                    var converter = new QuillDeltaToHtmlConverter(result.response.descriptionV2, []);
                    displayDesc.current = converter.convert();
                } else displayDesc.current = result.response.description;
                console.log(displayDesc.current);
                let commRes = await _fetch(
                    `${process.env.REACT_APP_API_URL}/comment?query_id=${result.response._id}`
                );
                if (commRes.status === 200) {
                    commRes = await commRes.json();
                    console.log(commRes);
                    setComments(commRes.response);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query_id]);

    async function uploadPhotos(op) {
        let url = await _fetch(
            `${process.env.REACT_APP_API_URL}/file/get_upload_url?name=${query_id}_${moment().format("MMDDYYYYhhmmss")}.jpeg&query=${query._id}`
        );
        if (url.status === 200) {
            url = await url.json();
            url = url.response;
            console.log(
                'url to upload generation Success with url ',
                url
            );
        } else {
            console.log('url to upload generation failed');
            throw new Error('url to upload generation failed');
        }

        var buf = new Buffer.from(
            op.insert.image.replace(/^data:image\/\w+;base64,/, ''),
            'base64'
        );
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'image/jpeg',
            },
            body: buf,
        };
        let res = await fetch(url, options);
        console.log('url is ', url);
        if (res.status === 200) {
            try {
                let parsedUrl = new URL(url);
                console.log(parsedUrl);
                let pathname = parsedUrl.pathname.slice(1);
                console.log(pathname);
                let fileInDB = await _fetch(
                    `${process.env.REACT_APP_API_URL}/file`,
                    {
                        method: 'POST',
                        body: {
                            query: query._id,
                            path: pathname,
                            type: 'image/jpeg'
                        },
                    }
                );
                if (fileInDB.status >= 200 && fileInDB.status < 300) {
                    fileInDB = await fileInDB.json();
                    op.insert.image = `${process.env.REACT_APP_API_URL}/file/get_object?id=${fileInDB.response._id}`;
                    return op;
                }
            } catch (e) {
                console.log(e);
                return {};
            }
        }
        return {};
    }

    const handleQueryUpdate = async (update) => {
        console.log(update);
        console.log(quillContent.current);
        let body = update;

        let { ops } = quillContent.current.content;
        if (ops) {
            let descriptionV2 = [];
            for (let i = 0; i < ops.length; i++) {
                console.log(ops[i].insert.image);
                if (ops[i].insert.image && !ops[i].insert.image.includes("http")) {
                    console.log("image");
                    let j = await uploadPhotos(ops[i]);
                    descriptionV2.push(j);
                } else descriptionV2.push(ops[i]);
            };
            body = { ...body, descriptionV2, description: "" }
        }

        console.log(body);

        let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/query/${query_id}`, {
            method: 'PATCH',
            body,
        });
        let resJson = await updatedQuery.json();
        console.log(resJson);
        if (resJson.status === 200) {
            console.log(resJson.response);
            setQuery(resJson.response);
            var converter = new QuillDeltaToHtmlConverter(resJson.response.descriptionV2, []);
            displayDesc.current = converter.convert();
            dispatch(changeQuery(resJson.response))
            setEditQuery(false);
        }
    };

    const handleQuill = (content, delta, source, editor) => {
        console.log("Dfnhms");

        quillContent.current.content = editor.getContents()
        quillContent.current.html = editor.getHTML();

    }

    function QueryForm() {
        console.log(query.description)
        return (
            <Form
                className='w-100'
                onFinish={handleQueryUpdate}
                form={form}
            >
                <div className='d-flex w-100 justify-content-between align-items-center'>
                    <Form.Item className='w-75' name='title' initialValue={query.title}>
                        <Input
                            value={query.title}
                            size='large'
                            autoFocus
                            bordered={false}
                            style={{
                                fontSize: '30px',
                                border: 0,
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            onClick={() => setEditQuery(false)}
                            size='large'
                            style={{ fontSize: '12px', marginRight: '10px' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            htmlType='submit'
                            size='large'
                            style={{ fontSize: '12px' }}
                        >
                            Update
                        </Button>
                    </Form.Item>
                </div>
                <div className='d-flex flex-column align-items-start my-4'>
                    <Typography.Title level={5}>Description</Typography.Title>

                    <RQuill
                        onChange={handleQuill}
                        queryID={query._id}
                        initValue={query.descriptionV2.length ? query.descriptionV2 : query.description}
                    />
                </div>
            </Form>
        );
    }

    return (
        <>
            <Card
                className='header-solid h-full mb-24'
                bordered={false}
                bodyStyle={{ height: '100%', marginBottom: '24px' }}
            >
                {loading ? (
                    <Loader />
                ) : query.query_id ? (
                    <Row gutter={[10, 10]} className='h-100'>
                        <Col span={17}>
                            <div className='p-2 h-auto'>
                                {editQuery ? (
                                    <QueryForm />
                                ) : (
                                    <div>
                                        <div className='d-flex w-100 justify-content-between align-items-center'>
                                            <Typography.Title
                                                level={2}
                                                className='my-auto'
                                            >
                                                {query.title}
                                            </Typography.Title>
                                            {role !== user_role.COMPANY && (
                                                <Button
                                                    type='link'
                                                    onClick={() => {
                                                        setEditQuery(true)
                                                    }}
                                                    size='large'
                                                    style={{ fontSize: '15px' }}
                                                    disabled={query.status === "CLOSED"}

                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </div>
                                        <div className='d-flex flex-column align-items-start my-4'>
                                            <Typography.Title level={5}>
                                                Description
                                            </Typography.Title>
                                            <div dangerouslySetInnerHTML={{ __html: displayDesc.current }} style={{ whiteSpace: "pre-line" }} />
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <Typography.Title
                                        level={5}
                                        className='align-self-start'
                                        disabled={query.status === "CLOSED"}
                                    >
                                        Files
                                    </Typography.Title>
                                    <Files id={query._id} queryStatus={query.status} />
                                    {/* <img src={image} /> */}
                                </div>
                                <Tabs type='card' className='my-4'>
                                    <Tabs.TabPane
                                        tab={
                                            <Typography.Title
                                                level={5}
                                                className='align-self-start'

                                            >
                                                Comments
                                            </Typography.Title>
                                        }
                                        key='comments'
                                    >

                                        <CommentView
                                            comments={comments}
                                            query_id={query._id}
                                            setComments={setComments}
                                            onCommentAdded={
                                                query.status === 'CLOSED' ? handleQueryUpdate : null
                                            }
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        tab={
                                            <Typography.Title
                                                level={5}
                                                className='align-self-start'
                                            >
                                                History
                                            </Typography.Title>
                                        }
                                        key='history'
                                    >
                                        <Activity
                                            query_id={query._id}
                                            canNavigate={false}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </Col>
                        <Col span={1}>
                            <Divider type='vertical' className='h-100' />
                        </Col>
                        <Col span={6} className>
                            <QuerySidebar
                                query={query}
                                className='p-2'
                                // company_id={props.company_id}
                                // selected_id={props.selected_id}
                                handleQueryUpdate={handleQueryUpdate}
                                role={role}
                            />
                        </Col>
                    </Row>
                ) : (
                    <div className='py-3 px-3'>
                        <Typography.Title level={3}>
                            The Query does not exist or you do not have access
                            :/
                        </Typography.Title>
                    </div>
                )}
            </Card>
        </>
    );
}
