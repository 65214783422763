import { _fetch } from "../../_fetch";

export const updateQuery = (query_id) => ({
    type: 'UPDATE_QUERY',
    payload: query_id
});
export const changeSelectedQuery = (selected) => ({
    type: 'CHANGE_SELECTED_QUERY',
    payload: selected
})
export const changeQuery = (payload) => ({
    type: 'ChangeQuery',
    payload: payload
})



export const getQueries = (company_id, fy_id) => {
    return async (dispatch) => {
        try {
            let url = `${process.env.REACT_APP_API_URL}/query?company_id=${company_id}`;
            if (fy_id) {
                url += `&fy_id=${fy_id}`;
            }
            let res = await _fetch(url);
            let result = await res.json();
            if (res.status === 200) {
                dispatch({ type: 'SET_ALL_QUERIES', payload: result.response });
                return result.response;
            } else {
                return [];
            }
        } catch (e) {
            console.log(e)
            throw (e);
        }
    }
}