module.exports = {
	user_status: {
		ACTIVE: 'ACTIVE',
		SUSPENDED: 'SUSPENDED',
	},
	user_role: {
		ADMIN: 'ADMIN',
		ACCOUNTANT: 'ACCOUNTANT',
		COMPANY: 'COMPANY'
	}
};
