import React, { useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons"
import { useSelector, useDispatch } from "react-redux";
import { getQueries } from "../../store/action/queries";
import CreateCompany from "../../pages/createCompany";
import CreateClient from "../../pages/createClient";
import CreateQuery from "../../pages/createQuery";
import { user_role } from "../../consts";

export default function ToolBar({ color = '#1890ff' }) {
    const dispatch = useDispatch()
    const [showQuery, setShowQuery] = useState(false);
    const [addCompanyModalVisible, setAddCompanyModalVisible] = useState(false);
    const [addClientModalVisible, setAddClientModalVisible] = useState(false);

    let { role } = useSelector(st => st.user);
    const { selected: selectedCompany } = useSelector(st => st.company);
    const { selected, all: allFys } = useSelector(st => st.fy);
    const [seed, setSeed] = useState(1);
    const reset = () => {
        setSeed(Math.random());
    }

    const refresh = () => {
        dispatch(getQueries(selectedCompany._id, selected._id))
        reset()

    }

    return (
        <div className="aside-footer">
            <div
                className="footer-box"
                style={{
                    background: color,
                }}
            >
                <Typography.Title level={4} style={{ color: "white" }}>
                    Create <PlusOutlined size={15} />
                </Typography.Title>
                <Row gutter={[8, 8]}>
                    {

                        <React.Fragment>
                            {role !== user_role.ACCOUNTANT && <Col span={24}>
                                <Button type="primary" style={{}} className="ant-btn-sm ant-btn-block" onClick={() => setAddCompanyModalVisible(true)}>
                                    Australian Firm
                                </Button>
                            </Col>}
                            <Col span={12}>
                                <Button type="primary" className="ant-btn-sm ant-btn-block" onClick={() => setAddClientModalVisible(true)}>
                                    Entity
                                </Button>
                            </Col>
                        </React.Fragment>
                    }
                    <Col span={12}>
                        <Button type="primary" className="ant-btn-sm ant-btn-block"
                            onClick={() => setShowQuery(true)}
                        >
                            Query
                        </Button>
                    </Col>
                </Row>
            </div>
            <CreateQuery refresh={refresh} show={showQuery} setShow={setShowQuery} />
            <CreateCompany visible={addCompanyModalVisible} setVisible={setAddCompanyModalVisible} />
            <CreateClient visible={addClientModalVisible} setVisible={setAddClientModalVisible} />
        </div>
    )
}