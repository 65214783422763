import { Avatar } from "antd";
import React from "react";

export default function UserInfo({
    firstName, lastName, avatraProps
}) {
    return (
        <Avatar.Group>
            {
                (firstName && lastName) &&
                <Avatar
                    className="shape-avatar my-auto"
                    shape="square"
                    {...avatraProps}
                >
                    {firstName[0]}{lastName[0]}
                </Avatar>
            }
            <div className="avatar-info" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <h5>{firstName} {lastName}</h5>
            </div>
        </Avatar.Group>
    )
}

UserInfo.defaltProps = {
    firstName: "",
    lastName: "",
    email: "",
    avatraProps: {}
}