import { useState } from 'react';
import { Input, message, Modal } from 'antd';
import useFetch from '../_fetch';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../store/action/client';

export default function CreateClient({ visible, setVisible }) {

	const _fetch = useFetch();
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState('');
	const { selected: selectedCompany } = useSelector(st => st.company);
	let dispatch = useDispatch();

	async function submit() {
		setLoading(true);
		try {
			let res = await _fetch(`${process.env.REACT_APP_API_URL}/client`, { method: "POST", body: { company_id: selectedCompany._id, title: selected } })
			let response = await res.json();
			if (res.status === 200) {
				message.success('Entity added successfully');
				setVisible(false);
				dispatch(getClients(selectedCompany._id))
			} else {
				if (response.response === "company_id is required") {
					message.error("Please add a company before adding a client")
				} else { message.error('Some error occurred while adding client') };
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Modal
			visible={visible} onCancel={() => setVisible(false)}
			title={<h6 className="font-semibold m-0">Add new Entity</h6>}
			okText="Add Entity" onOk={submit}
			okButtonProps={{ loading: loading }} cancelButtonProps={{ loading: loading }}
		>
			<label>Entity Name</label>
			<Input onChange={(ev) => setSelected(ev.target.value)} />
		</Modal>
	);
}
