const initState = {
    jwtToken: '',
    fullName: '',
    email: '',
    _id: '',
    role: '',
    all: []
}

export const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN': {
            return ({
                ...state,
                ...action.payload
            });
        }
        case 'LOGOUT':
            return ({});
        case 'SET_ALL_USERS':
            
            return ({
                ...state,
                all: action.payload
            })
        default:
            return state;
    }
}