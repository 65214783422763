import {
  Col,
  Row,
  Select,
} from "antd";
import { user_role } from "../../consts";
import { useSelector } from "react-redux";
import UserInfo from "../userInfo";

export default function FySidebar({ query, handleFyUpdate, role }) {
  const { all: all_users } = useSelector((st) => st.user);
  const { selected } = useSelector((st) => st.company);


  // const statusMenu = (
  //     <Menu defaultValue={[query.status]}>
  //         <Menu.Item
  //             value="OPEN"
  //             onClick={() => handleQueryUpdate({ status: "OPEN" })}
  //         >
  //             Open
  //         </Menu.Item>
  //         <Menu.Item
  //             value="CLOSED"
  //             onClick={() => handleQueryUpdate({ status: "CLOSED" })}
  //         >
  //             Closed
  //         </Menu.Item>
  //     </Menu>
  // );

  return (
    <Row gutter={[10, 25]} className="d-flex flex-column align-items-start" style={{ width: "250px" }}>
      {/* <Col span={24}>
          <h6 className="font-semibold">ID: {query.query_id}</h6>
        </Col> */}
      {/* <Col span={24} className="w-100">
          {role === user_role.COMPANY ? (
            <Button
              type="primary"
              danger={query.status === "CLOSED"}
              className="w-50 mx-auto"
            >
              {query.status}
            </Button>
          ) : (
            <Dropdown trigger={["click"]} overlay={statusMenu} arrow>
              <Button
                type="primary"
                danger={query.status === "CLOSED"}
                className="w-50 d-flex justify-content-between"
              >
                {query.status}
                <BsChevronDown size={20} />
              </Button>
            </Dropdown>
          )}
        </Col> */}
      <Col span={24} className="w-100">

        <div className="mt-2">
          {role === user_role.COMPANY ? (
            <UserInfo
              firstName={query.assigned_to?.first_name || "Unassigned"}
              lastName={query.assigned_to?.last_name}
            />
          ) : (
            <Select

              value={
                query.assigned_to?.first_name && query.assigned_to?.last_name
                  ? `${query.assigned_to?.first_name} ${query.assigned_to?.last_name}`
                  : "Unassigned"
              }

              size="large"
              style={{ minWidth: "100%" }}
              onChange={(e) => handleFyUpdate({ assigned_to: e })}
            >
              {all_users
                ?.filter(
                  (e) =>
                    e.role === user_role.ADMIN ||
                    e.company_ids.some((f) => f._id === selected._id)
                )
                ?.map((g) => (
                  <Select.Option value={g._id} key={g._id}>
                    <UserInfo
                      firstName={g.first_name}
                      lastName={g.last_name}
                    />
                  </Select.Option>
                ))}
            </Select>
          )}
        </div>
      </Col>
    </Row>
  );
}
