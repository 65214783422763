import { useEffect, useState } from "react";
import { Input } from "antd";

export function Search_box({ all, setshow, name }) {
	const [text, setText] = useState("");

	useEffect(() => {
		if (all.length) {
			let filtered = all.filter(f => `${f.title}`.toLowerCase().includes(text.toLowerCase()));
			setshow(filtered);
			console.log(filtered);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [all, text])

	const onSearch = (e) => {
		setText(e.target.value)
		
	};


	return <Input
		placeholder= {name}
		onChange={onSearch}
		/>
		
}

