const initState = {
    _id: [],
    selected: "",
    data: {},
    all: []
}

export const clientReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_CLIENT': {
            return {
                ...state, all: state.all.map((e) => {
                    if (e._id === action.payload._id) {
                        return action.payload;
                    }
                    return e
                })
            }


        }

        // eslint-disable-next-line no-fallthrough
        case 'CHANGE_SELECTED_CLIENT': {
            return ({
                ...state,
                selected: action.payload
            })
        }
        case 'SET_ALL_CLIENTS': {
            return ({
                ...state,
                all: action.payload
            })
        }
        default:
            return state;
    }
}