import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllUsers, login } from '../store/action/user';
import useFetch from '../_fetch';
import { useCookies } from 'react-cookie';
import { message, Tooltip, Modal } from 'antd';
import { Button, Row, Col, Typography, Form, Input } from 'antd';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const { Title } = Typography;
export default function SignIn() {
    const [, setCookie] = useCookies(['userInfo']);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();

    let _fetch = useFetch();
    let navigate = useNavigate();

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        // window.ps = document.getElementById('otpInput');
        let otp = document.getElementById('otpInput').value;
        console.log(
            'this is otp value:',
            document.getElementById('otpInput').value
        );
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}/user/otp`;
            console.log('fetching , url');
            let res = await _fetch(url, {
                method: 'POST',
                body: {
                    email: window.email,
                    otp,
                },
            });
            let resData;
            if (res.status === 200) {
                resData = await res.json();
                console.log('got response of otp submit', resData);
                dispatch(login({ ...resData.response }));
                setCookie(
                    'userInfo',
                    { ...resData.response },
                    {
                        maxAge:
                            process.env.REACT_APP_USER_COOKIE_EXPIRY * 3600000,
                    }
                );
                dispatch(getAllUsers());
                navigate('/');
            } else {
                console.log({ res, resData: await res.json() })
                message.error('entered OTP is incorrect');
            }
        } catch (e) {
            console.error('otp submit post failed', e);
            // message.error('entered OTP is incorrect');
        } finally {
            setLoading(false);
            setOpen(false);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const _login = async (values) => {
        try {
            const recaptchaToken = await executeRecaptcha();
            console.log(recaptchaToken);
            setLoading(true);
            let body = {
                email: values.email,
                password: values.password,
                recaptchaToken
            }
            //generating token to be sent to server to be checked
            let res = await _fetch(
                `${process.env.REACT_APP_API_URL}/user/login`,
                {
                    method: 'POST',
                    body
                }
            );
            var result = await res.json();

            if (result.success === true) {
                window.email = values.email;

                showModal();
                // dispatch(login({ ...result.response }));
                // setCookie('userInfo', { ...result.response }, { maxAge: process.env.REACT_APP_USER_COOKIE_EXPIRY * 3600000 });
                // dispatch(getAllUsers());
                // navigate('/');

                // sendOTP
            } else {
                message.error(result.error || result.response);
            }
        } catch (err) {
            console.log(err);
            message.error(
                'You are offline, please check your network connection'
            );
        } finally {
            console.log(result);
            setLoading(false);
        }
    };

    return (
        <>
            {/* <Button type='primary' onClick={showModal}>
                Open Modal with customized footer
            </Button> */}
            <Modal
                width={200}
                destroyOnClose
                visible={open}
                title='Enter OTP'
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                footer={[
                    <Button
                        key='submit'
                        type='primary'
                        loading={loading}
                        onClick={handleOk}
                    >
                        Submit
                    </Button>,
                    // <Button
                    // 	key="link"
                    // 	href="https://google.com"
                    // 	type="primary"
                    // 	loading={loading}
                    // 	onClick={handleOk}
                    // >
                    // 	Search on Google
                    // </Button>,
                ]}
            >
                <Input id='otpInput' label='otp' />
            </Modal>

            <Row gutter={[24, 0]} justify='space-around'>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    lg={{ span: 10, offset: 2 }}
                    md={{ span: 12 }}
                >
                    <Title className='mb-15'>Sign In</Title>
                    <Title className='font-regular text-muted' level={5}>
                        Enter your email and password to sign in
                    </Title>
                    <Form
                        onFinish={_login}
                        onFinishFailed={onFinishFailed}
                        layout='vertical'
                        className='row-col'
                        name='login'
                    >
                        <Form.Item
                            className='username'
                            label='Email'
                            name='email'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input placeholder='Email' />
                        </Form.Item>

                        <Form.Item
                            className='username'
                            style={{ marginBottom: '5px' }}
                            label='Password'
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input placeholder='Password' type='password' />
                        </Form.Item>

                        <Button type='link' className='mb-3' onClick={() => navigate('/forgot-password')}>
                            Forgot Password?
                        </Button>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ width: '100%' }}
                                loading={loading}
                            >
                                SIGN IN
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
}
