const initState = {
    _id: [],
    selected: {},
    data: {},
    all: [],
    grouped: []
}

export const financialYearReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_CLIENT': {
            return ({
                ...state,
                ...action.payload
            });
        }
        case 'CHANGE_SELECTED_YEAR': {
            return ({
                ...state,
                selected: action.payload
            })
        }
        case 'SET_ALL_YEARS': {
            return ({
                ...state,
                all: action.payload
            })
        }
        case 'SET_ALL_YEARS_GROUPED': {
            return ({
                ...state,
                grouped: action.payload
            })
        }
        default:
            return state;
    }
}