import { Form, Input, message, Modal, Select, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import UserInfo from "../components/userInfo";
import { user_role } from "../consts";
import useFetch from "../_fetch";

export default function CreateQuery({
    show, setShow, refresh
}) {

    const _fetch = useFetch();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();


    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const { Option } = Select;

    const { selected: selectedClient, all: all_clients } = useSelector(st => st.client);
    const { selected, grouped: all_fys } = useSelector(st => st.fy);

    async function submit(values) {
        setLoading(true);
        console.log(values)
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/query`, {
                method: "POST",
                body: {
                    ...values,
                    descriptionV2: values.descriptionV2 ? [{ insert: values.descriptionV2 }] : []
                }
            });
            await res.json();
            if (res.status === 200) {
                message.success('Query created successfully');
                setShow(false);
                refresh()

            } else {
                message.error('Some error occurred while opening query');

            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal visible={show}
            title="Create Query"
            destroyOnClose
            onCancel={() => setShow(false)}
            okText='Open Query'
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        submit(values);
                        // setTimeout(() => { console.log("component refreshed"); refresh() }, 5000);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okButtonProps={{ loading: loading, htmlType: "submit" }}
            cancelButtonProps={{ loading }}
        >
            <Form layout="vertical" {...layout} onFinish={submit} form={form} initialValues={{ financialYear: selected._id, client_id: selectedClient._id }}>
                <Form.Item
                    label="Entity"
                    name="client_id"
                >
                    <Select defaultValue={selectedClient._id}>
                        {
                            all_clients.map(e => (
                                <Option value={e._id} key={e._id}>{e.title}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.client_id !== currentValues.client_id}
                >
                    {({ getFieldValue }) =>
                        <Form.Item
                            label="Financial Year"
                            name="financialYear"
                        >
                            <Select defaultValue={selected._id}>
                                {
                                    all_fys.find(e => e._id === getFieldValue('client_id'))?.financial_year.map(e => (
                                        <Option value={e._id} key={e._id}>{moment(e.start).format('LL')} - {moment(e.end).format('LL')}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    }
                </Form.Item>
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Query title is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="descriptionV2"
                >
                    <TextArea rows={5} />
                </Form.Item>
            </Form>
        </Modal>
    )
}