import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const { Header, Footer, Content } = Layout;

export default function LayoutAlt({children}) {

	let { _id } = useSelector(st => st.user);

	if(_id) {
		return <Navigate to='/' />
	}

	return (
		<>
			<Layout className="layout-default layout-signin min-vh-100">
				<Header>
					<div className="header-col header-brand">
						<h5>{process.env.REACT_APP_NAME}</h5>
					</div>
					<div className="header-col header-nav">
						<Menu mode="horizontal" defaultSelectedKeys={['1']}>
							<Menu.Item key="4">
								<Link to="/sign-in">
									<LoginOutlined />
									<span> Sign In</span>
								</Link>
							</Menu.Item>
						</Menu>
					</div>
				</Header>
				<Content className="signin">
					{children}
				</Content>
				{/* <Footer>
					<p className="copyright">
						{' '}
						Built proudly by
						<a href="http://gargtechsolutions.in">Garg Tech</a>.{' '}
					</p>
				</Footer> */}
			</Layout>
		</>
	);
}
