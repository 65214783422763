import { _fetch } from "../../_fetch";

export const updateClient = (response) => ({
    type: 'UPDATE_CLIENT',
    payload: response
});
export const changeSelectedClient = (selected) => ({
    type: 'CHANGE_SELECTED_CLIENT',
    payload: selected
})

export const getClients = (companyId) => {
    return async (dispatch) => {
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/client?company_id=${companyId}`)
            let result = await res.json();
            if (res.status === 200)
                if (Array.isArray(result.response) && result.response.length && result.response[0].clients) {
                    let proj = result.response[0].clients;
                    dispatch({ type: 'SET_ALL_CLIENTS', payload: proj });
                    console.log(proj);
                    return proj;
                } else {
                    dispatch({ type: 'SET_ALL_CLIENTS', payload: [] });
                    return [];
                }
        } catch (e) {
            console.log(e)
            throw (e);
        }
    }
}