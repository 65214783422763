/* eslint-disable no-loop-func */
import { Button, Tag, Upload, Space, Image, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { storeFiles } from '../../store/action/files';
import { CgAttachment } from 'react-icons/cg';
import useFetch from '../../_fetch';
import { Buffer } from 'buffer';
import './styles.css';

import { AiFillCloseSquare } from 'react-icons/ai';

export default function Files({ id, queryStatus }) {
    const _fetch = useFetch();
    const dispatch = useDispatch();

    const [queryFiles, setQueryFile] = useState([]);
    const [filesUpload, setFilesUpload] = useState([]);
    const uploadUrl = useRef();
    console.log(queryFiles)


    const beforeUpload = async (file) => {
        try {
            console.log('beforeUpload ran with file', file);
            let name = file.name.split('.')[0]
            let ext = file.name.split('.')[1];
            name = `${name}-${moment().valueOf()}.${ext}`
            console.log(name);
            let url = await _fetch(
                `${process.env.REACT_APP_API_URL}/file/get_upload_url?name=${name}&query=${id}`
            );
            if (url.status === 200) {
                url = await url.json();
                uploadUrl.current = url.response;
                console.log(
                    'url to upload generation Success with url ',
                    uploadUrl
                );
            } else {
                console.log('url to upload generation failed');
                throw new Error('url to upload generation failed');
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        async function init() {
            try {
                let files = await _fetch(
                    `${process.env.REACT_APP_API_URL}/file?query=${id}`
                );
                if (files.status === 200) {
                    files = await files.json();

                    setQueryFile(files.response);
                    dispatch(storeFiles(files.response));
                }
            } catch (e) {
                console.log(e);
            }
        }

        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileClick = async (e) => {
        window.open(e.path, '_blank', 'noopener,noreferrer');
    };

    const upload = async (data, screenShot = false) => {
        console.log('trying to upload an image on url:', uploadUrl.current);
        console.log('uploading data', { screenShot, data });
        try {
            let options;
            let contentType = "";
            // check if screenshot
            if (!screenShot) {
                let file = data.file;
                contentType = file.type;
                console.log(file);
                options = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': contentType,
                    },
                    body: file,
                };
            } else {
                // custom upload for ctrl+V screenShots ,Reason: getting Image in base64 format :AWS different way of upload for base64
                console.log('trying to upload screenshot');
                contentType = 'image/jpeg';
                try {
                    var buf = new Buffer.from(
                        data.replace(/^data:image\/\w+;base64,/, ''),
                        'base64'
                    );
                    options = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': contentType,
                        },
                        body: buf,
                    };
                } catch (e) {
                    console.warn('error uploading screenshot', String(e));
                }
            }

            let res = await fetch(uploadUrl.current, options);
            // console.log('url is ', url);
            if (res.status === 200) {
                try {
                    let parsedUrl = new URL(uploadUrl.current);
                    // console.log(parsedUrl);
                    let pathname = parsedUrl.pathname.slice(1);
                    // console.log(pathname);
                    let uploadedRes = await _fetch(
                        `${process.env.REACT_APP_API_URL}/file`,
                        {
                            method: 'POST',
                            body: {
                                query: id,
                                path: pathname,
                                type: contentType
                            },
                        }
                    );
                    if (uploadedRes.status === 200) {
                        uploadedRes = await uploadedRes.json();
                        setQueryFile((f) => [uploadedRes.response, ...f]);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <div>
                {queryFiles.filter((s) => s.commentId === null).length
                    ? queryFiles
                        .filter((s) => s.commentId === null)
                        .map((e) => {
                            let name = e.name.split('.');
                            console.log(name);
                            let ext = name[name.length - 1]
                            name.pop();
                            console.log(name);
                            name = name.join(".");
                            return (
                                <Tag
                                    className='pointer m-1'
                                    style={{
                                        padding: '9px',
                                        borderRadius: '5px',
                                        maxWidth: '180px',
                                    }}
                                >
                                    <div className="d-flex flex-column">
                                        {(e.type === "image/jpeg" || e.type === "image/png" || e.type === "image/jpg") ?
                                            <Image src={e.path} /> : null
                                        }

                                        <div className="d-flex flex-column" onClick={() => handleFileClick(e)}>
                                            <div className='d-flex'>
                                                <div className='elipText'>{name}</div>
                                                <div className='elipText' style={{ maxWidth: "50px" }}>.{ext}</div>
                                            </div>
                                            <Typography.Text>{moment.utc(e.createdAt).format("LLL")}</Typography.Text>
                                        </div>
                                    </div>

                                </Tag>
                            )
                        })
                    : null}
            </div>
            <Space>
                <Upload
                    beforeUpload={beforeUpload}
                    customRequest={upload}
                    showUploadList={false}
                >
                    <Button icon={<CgAttachment size={15} />} className='me-1' disabled={queryStatus === "CLOSED"}>
                        Attach File
                    </Button>

                </Upload>


                {filesUpload.map((e, index) => (
                    <Tag
                        className='pointer m-1'
                        style={{
                            padding: '4px',
                            borderRadius: '5px',
                            maxWidth: '180px',
                        }}
                        onClick={() =>
                            setFilesUpload((e) => {
                                delete e[index];
                                return e.filter((sm) => Boolean(sm));
                            })
                        }
                    >
                        <span
                            style={{ display: 'inline' }}
                            className='myDIV border'
                        //   className='border'
                        //   style={{ margin: '100px', display: 'block' }}
                        >
                            {e.fileName}

                        </span>
                        <AiFillCloseSquare style={{}} className='hide border' />
                    </Tag>
                ))}
            </Space>
        </div >
    );
}
