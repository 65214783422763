import {
  Button,
  Dropdown,
  Menu,
} from "antd";
import { BsChevronDown } from "react-icons/bs";

export default function FYPrioritySelector({
    item, handlePriorityUpdate, isDisabled = true
}){
    const priorityMenu = (
        <Menu defaultValue={[item.priority]}>
            <Menu.Item
                value="NORMAL"
                onClick={()=>{
                    handlePriorityUpdate(item,'NORMAL')
                }}
            >Normal</Menu.Item>
            <Menu.Item
                value="HIGH"
                onClick={()=>{
                    handlePriorityUpdate(item,"HIGH")
                }}
            >High</Menu.Item>
            <Menu.Item
                value="HIGHEST"
                onClick={()=>{
                    handlePriorityUpdate(item,"HIGHEST")
                }}
            >Highest</Menu.Item>
            <Menu.Item
                value="URGENT"
                onClick={()=>{
                    handlePriorityUpdate(item,"URGENT")
                }}
            >Urgent</Menu.Item>
            <Menu.Item
                value="MOST URGENT"
                onClick={()=>{
                    handlePriorityUpdate(item,"MOST URGENT")
                }}
            >Most Urgent</Menu.Item>
        </Menu>
    );
    return (
        <Dropdown
            trigger={["click"]}
            overlay={priorityMenu}
            arrow
            disabled={isDisabled}
        >
            <Button
                type="text"
                style={{
                    color: item.priority === "NORMAL" ? "#1890ff" : item.priority === "HIGH" ? "orange" : item.priority === "HIGHEST" ? "red" : item.priority === "URGENT" ? "purple" : item.priority === "MOST URGENT" ? "black" : "grey",
                }}
            >
                {
                    item.priority ? item.priority : "Set Priority"
                }
                {isDisabled ? null : <BsChevronDown />}
            </Button>
        </Dropdown>
    );
}