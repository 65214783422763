import { Row, Col, Card, Button, List, Typography, message, Collapse, Input } from 'antd';
import { BsPlus } from 'react-icons/bs';
import { DownOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { changeSelectedClient, updateClient } from '../store/action/client';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from "@ant-design/icons"
import { getClients } from '../store/action/client';
import { changeSelectedFY, getFinancialYears } from '../store/action/fy';
import DropDown from "./DropDown";
import { Search_box } from '../components/Search_box/search_box';
import Fyassign from '../components/querySidebar/fyAsing';
import FYPrioritySelector from '../components/prioritySelector';
import useFetch from "../_fetch";
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai'
export default function Home() {

	const [FYFormModalVisible, setFYFormModalVisible] = useState('');
	const [editQuery, setEditQuery] = useState(false)
	const navigate = useNavigate();
	let dispatch = useDispatch();

	const { Panel } = Collapse;
	const [fyy, setFyy] = useState("")
	const [fyPriority, setFyPriority] = useState("")
	const placeholder = "Search Entities..."
	const [toggle, setTogle] = useState(false)
	const [show, setShow] = useState();
	const [title, setTitle] = useState("");
	const { selected: selectedCompany } = useSelector(st => st.company);
	const { all: client } = useSelector(st => st.client);
	const [handleSorting, setHandleSorting] = useState(true)
	const { grouped: fy } = useSelector(st => st.fy);
	const user = useSelector(st => st.user)
	console.log(show, "Line N0 28")

	const _fetch = useFetch()

	function sortEntityDec() {
		let sortedData = show.sort(function (a, b) {
			if (a.title.toUpperCase() > b.title.toUpperCase()) {
				return -1;
			}
			if (a.title.toUpperCase() < b.title.toUpperCase()) {
				return 1;
			}
			return 0;
		});
		setShow(sortedData)
		setHandleSorting((pre) => !pre)
	}

	function sortEntityAse() {


		let sortedData = show.sort(function (a, b) {
			if (a.title.toUpperCase() < b.title.toUpperCase()) {
				return -1;
			}
			if (a.title.toUpperCase() > b.title.toUpperCase()) {
				return 1;
			}
			return 0;
		});
		setShow(sortedData)
		setHandleSorting((pre) => !pre)
	}

	useEffect(() => {
		async function getClient() {
			if (selectedCompany) {

				try {
					let clients = await dispatch(await getClients(selectedCompany._id));
					await dispatch(await getFinancialYears(Array.isArray(clients) && clients.length ? clients.map(e => e._id) : []));
				} catch (e) {
					message.error('Some error occurred while fetching data');
					console.log(e);
				}
			}
		}

		getClient();
		// eslint-disable-next-line react-hooks/exhaustive-deps

	}, [selectedCompany, toggle])

	const handleFyUpdate = async (update) => {
		const payload = {
			...update,
			fy: fyy
		}
		let updatedQuery = await _fetch(
			`${process.env.REACT_APP_API_URL}/fy`,
			{
				method: 'PATCH',
				body: payload,
			}
		);
		let resJson = await updatedQuery.json();

		if (resJson.status === 200) {
			setTogle((pre) => !pre)
		}
	};
	const handlePriorityUpdate = async (item, newPriority) => {
		if (item.priority !== newPriority){
			try {
				const payLoad = {
					fy: item._id,
					priority: newPriority
				}
				let updatedQuery = await _fetch(
					`${process.env.REACT_APP_API_URL}/fy`,
					{
						method: 'PATCH',
						body: payLoad,
					}
				);
				let resJson = await updatedQuery.json();
				if (resJson.status === 200) {
					message.success('Priority updated successfully');
					setTogle((pre) => !pre)
				} else {
					message.error('Some error occurred while updating priority');
				}
			} catch {
				message.error('Some error occurred while updating priority');
			}
		}
		return;
	}

	const UpdateClient = async (client_id) => {
		let updatedClient = await _fetch(`${process.env.REACT_APP_API_URL}/client/${client_id}`, {
			method: 'PATCH',
			body: { "title": title }
		});
		let resdata = await updatedClient.json();
		console.log(resdata)
		dispatch(updateClient(resdata.response))


	}
	console.log(show)

	function Showupdate({ data }) {

		return (
			<div className='d-flex w-100 align-items-center justify-content-start'>

				<Input
					value={title}
					size='large'
					autoFocus
					bordered={false}
					style={{
						fontSize: '24px',
						width: "30%",
						border: 0,
					}}
					onChange={(e) => {
						setTitle(e.target.value)
					}}
				/>


				<Button
					onClick={() => setEditQuery(false)}
					size='large'
					style={{ fontSize: '12px', marginRight: '10px' }}
				>
					Cancel
				</Button>
				<Button
					type='primary'
					onClick={() => {
						UpdateClient(data._id)
						setEditQuery(false)
					}}
					size='large'
					style={{ fontSize: '12px' }}
				>
					Update
				</Button>
			</div>


		);

	}

	return (
		<>
			<Row gutter={[24, 0]}>
				<Col xs={24} className="mb-<FySidebar 24">
					<Card
						className="header-solid h-full"
						bordered={false}
						title={[
							<div className='d-flex'>
								<span className="font-semibold m-0">
									Entities
								</span>
								<div className="flex-fill search d-flex">
									<div className="d-flex flex-fill justify-content-center">
										<Search_box all={client} setshow={setShow} name={placeholder} />
									</div>
								</div>
								<Button type='text' onClick={handleSorting ? sortEntityAse : sortEntityDec}>{handleSorting ? <AiOutlineSortAscending size={'20px'} /> : <AiOutlineSortDescending size='20px' />}</Button>
							</div>
						]}
						bodyStyle={{ paddingTop: '0' }}
					>
						{
							show?.map((proj, index) => (
								<>
									<Collapse ghost>
										<Panel
											header={
												editQuery === proj._id ? (
													<Showupdate data={proj} />
												) : (
													<div className='d-flex w-100 justify-content-between align-items-center'>
														<Typography.Title
															level={4}
															className='my-auto'
														>
															{proj.title}
															<EditOutlined type='link' style={{ marginLeft: 10 }} onClick={() => {
																setEditQuery(proj._id)
																setTitle(proj.title)
															}} />
														</Typography.Title>

													</div>
												)
											}
											extra={
												user.role === 'COMPANY' ? null : <div className="col-action" onClick={() => setFYFormModalVisible(proj._id)}>
													<DropDown client_id={FYFormModalVisible} />
												</div>
											}
											style={{ fontSize: "20px", fontWeight: "600" }}
										>
											<div>
												<Card

													className="card-billing-info d-flex my-3"
													bordered="false"
													key={index}
												>
													<div>
														{
															fy
																.filter(f => f.client_id === proj._id)
																.map(e => (
																	<List
																		key={e._id}
																		itemLayout="horizontal"
																		dataSource={e.financial_year}
																		className='ms-3 mt-3'
																		rowKey={(item) => item._id}
																		renderItem={(item) => (
																			<List.Item
																			>
																				<List.Item.Meta

																					title={
																						<div>
																							<p onClick={() => {
																								dispatch(changeSelectedFY(item));
																								dispatch(changeSelectedClient(proj))
																								navigate(`/queries?fy=${item._id}&entity=${proj._id}&company=${selectedCompany._id}`);
																							}} className="pointer" style={{ width: "max-content" }}>{`${moment(item.start).format('LL')} - ${moment(item.end).format('LL')}`}</p>
																							<FYPrioritySelector
																								item={item}
																								handlePriorityUpdate={handlePriorityUpdate}
																								isDisabled={!(user.role === 'ADMIN' || user.role === 'ACCOUNTANT')}
																							/>
																						</div>
																					}
																				/>

																				<div style={{ display: "flex", alignItems: "center", gap: "10px" }} onClick={() => setFyy(item._id)}>
																					<Fyassign query={item}
																						className='p-2'
																						handleFyUpdate={handleFyUpdate}
																						role={""} />
																					<Button
																						type="primary"
																						onClick={() => {
																							dispatch(changeSelectedFY(item));
																							dispatch(changeSelectedClient(proj))
																							navigate(`/queries?fy=${item._id}&entity=${proj._id}&company=${selectedCompany._id}`);
																						}}
																					>
																						Show Queries
																					</Button>
																				</div>
																			</List.Item>
																		)} />
																))}
													</div>
												</Card></div></Panel></Collapse></>
							))
						}
					</Card>
				</Col>
			</Row>
		</>
	);
}
