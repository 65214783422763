import {
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
} from "antd";
import { user_role } from "../../consts";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch } from "react-redux";

export default function QuerySidebar({ query, handleQueryUpdate, role, }) {


  const statusMenu = (
    <Menu defaultValue={[query.status]}>
      <Menu.Item
        value="OPEN"
        onClick={() => {
          handleQueryUpdate({ status: "OPEN" })
        }}
      >
        Open
      </Menu.Item>
      <Menu.Item
        value="CLOSED"
        onClick={() => handleQueryUpdate({ status: "CLOSED" })}
      >
        Closed
      </Menu.Item>
    </Menu>
  );


  return (
    <Row gutter={[10, 25]} className="d-flex flex-column align-items-start">
      <Col span={24}>
        <h6 className="font-semibold">ID: {query.query_id}</h6>
      </Col>
      <Col span={24} className="w-100">
        {role === user_role.COMPANY ? (
          <Button
            type="primary"
            danger={query.status === "CLOSED"}
            className="w-50 mx-auto"
          >
            {query.status}
          </Button>
        ) : (
          <Dropdown trigger={["click"]} overlay={statusMenu} arrow>
            <Button
              style={{ background: query.status === "CLOSED" ? "green" : "#1890ff", borderColor: query.status === "CLOSED" ? "green" : null }}
              type="primary"
              className="w-50 d-flex justify-content-between"
            >
              {query.status}
              <BsChevronDown size={20} />
            </Button>
          </Dropdown>
        )}
      </Col>
    </Row>
  );
}