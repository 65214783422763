import { useState } from 'react';
import { Input, message, Modal } from 'antd';
import useFetch from '../_fetch';
import { getAllCompanies } from '../store/action/company';
import { useDispatch } from 'react-redux';

export default function CreateCompany({ visible, setVisible }) {

	const _fetch = useFetch();
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState('');
	let dispatch = useDispatch();

	function submit() {
		setLoading(true);
		_fetch(`${process.env.REACT_APP_API_URL}/company`, { method: "POST", body: { name: selected } })
			.then(res => res.json())
			.then(res => {
				message.success('Australian Firm added successfully');
				dispatch(getAllCompanies());
				setVisible(false);
			})
			.catch(err => {
				message.error('Some error occurred while adding company');
				console.log(err);
			})
			.finally(() => setLoading(false));
	}

	return (
		<Modal
			visible={visible} onCancel={() => setVisible(false)}
			title={<h6 className="font-semibold m-0">Add new Australian Firm</h6>}
			okText="Add Australian Firm" onOk={submit}
			okButtonProps={{ loading: loading }} cancelButtonProps={{ loading: loading }}
		>
			<label>Australian Firm Name</label>
			<Input onChange={(ev) => setSelected(ev.target.value)} />
		</Modal>
	);
}
