const initState = {
    _id: [],
    selected: "",
    queries: []
}

export const QueriesReducer = (state = initState, action) => {
    console.log(action.payload);
    switch (action.type) {
        case 'UPDATE_QUERY': {
            return ({
                ...state,
                ...action.payload
            });
        }
        case 'CHANGE_SELECTED_QUERY': {
            return ({
                ...state,
                selected: action.payload
            });
        }
        case 'ChangeQuery': {
            console.log(action.payload)
            console.log(state.queries);
            let temp = {
                ...state, queries: state.queries.map((e) => {
                    if (e._id === action.payload._id) {
                        return action.payload;
                    }
                    return e
                })

            }
            console.log(temp)
            return temp;
        }
        case 'SET_ALL_QUERIES': {
            return ({
                ...state,
                queries: action.payload
            });
        }
        default:
            return state;
    }
}