import { Form, Input, message, Modal, Radio, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { user_role } from "../consts";
import { getAllUsers } from "../store/action/user";

import useFetch from "../_fetch";

export default function CreateUsers({ show, setShow, selectedUser }) {

    const _fetch = useFetch();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();


    let dispatch = useDispatch();

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const { Option } = Select;

    const { all } = useSelector(st => st.company);
    const { all: allUsers } = useSelector(st => st.user);

    async function submit(values) {
        setLoading(true);
        console.log(values)
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/user`,
                {
                    method: selectedUser ? "PATCH" : "POST",
                    body: selectedUser ? { user_id: selectedUser, ...values } : values
                }
            );
            let result = await res.json();
            if (res.status === 200) {
                message.success(result.response);
                setShow(false);
                dispatch(getAllUsers());
            }
            else {
                message.error(result.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log(selectedUser);
        if (selectedUser) {
            let initial = allUsers?.find(e => e._id === selectedUser) || {};
            if (initial.company_ids) {
                initial.company_ids = initial.company_ids.map(e => e._id);
            }
            form.setFieldsValue(initial);
        } else form.resetFields()
    }, [form, selectedUser, allUsers]);


    return (
        <Modal visible={show}
            title={selectedUser ? 'Edit User' : 'Add User'}
            onCancel={() => setShow(false)}
            okText={selectedUser ? 'Update User' : 'Add User'}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        submit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okButtonProps={{ loading: loading, htmlType: "submit" }}
            cancelButtonProps={{ loading }}
            destroyOnClose={true}
        >
            {
                selectedUser ?
                    <Form
                        layout="vertical" {...layout}
                        onFinish={submit} form={form}
                        initialValues={allUsers?.find(e => e._id === selectedUser)}
                    >

                        <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[{ required: true, message: 'First name is required' }]}
                        >
                            <Input placeholder="John" />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={[{ required: true, message: 'Last name is required' }]}
                        >
                            <Input placeholder="Doe" />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Email is required' }]}
                        >
                            <Input placeholder="test@example.com" type='email' />
                        </Form.Item>
                        <Form.Item
                            label="Role"
                            name="role"
                            rules={[{ required: true, message: 'Role is required' }]}
                        >
                            <Select>
                                <Option value={user_role.ADMIN}>Admin</Option>
                                <Option value={user_role.ACCOUNTANT}>Accountant</Option>
                                <Option value={user_role.COMPANY}>Australian Firm User</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Entity"
                            name="company_ids"
                            rules={[
                                (form) => {
                                    if (form.getFieldValue('role') === 'ACCOUNTANT') {
                                        return ({
                                            min: 1,
                                            type: 'array',
                                            message: 'Please assign at least one company to the user'
                                        })
                                    } else if (form.getFieldValue('role') === 'COMPANY') {
                                        return ({
                                            max: 1,
                                            type: 'array',
                                            message: 'More than one company cannot be assigned to a user with Client role'
                                        })
                                    } else {
                                        return ({ required: false })
                                    }
                                }
                            ]}
                        >
                            <Select mode="multiple">
                                {
                                    all?.map(e => (
                                        <Option value={e._id} key={e._id}>{e.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[{ required: true, message: 'Role is required' }]}
                        >
                            <Radio.Group
                                options={[
                                    { label: 'Active', value: 'ACTIVE' },
                                    { label: 'Suspended', value: 'SUSPENDED' }
                                ]}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </Form.Item>
                    </Form>
                    :
                    <Form
                        layout="vertical" {...layout}
                        onFinish={submit} form={form}
                    >
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[{ required: true, message: 'First name is required' }]}
                        >
                            <Input placeholder="John" />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={[{ required: true, message: 'Last name is required' }]}
                        >
                            <Input placeholder="Doe" />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Email is required' }]}
                        >
                            <Input placeholder="test@example.com" type='email' />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Password is required' }]}
                        >
                            <Input placeholder="*******" type='password' />
                        </Form.Item>
                        <Form.Item
                            label="Role"
                            name="role"
                            rules={[{ required: true, message: 'Role is required' }]}
                        >
                            <Select>
                                <Option value="ADMIN">Admin</Option>
                                <Option value="ACCOUNTANT">Accountant</Option>
                                <Option value="COMPANY">Australian Staff</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}
                        >
                            {({ getFieldValue }) =>
                                <Form.Item
                                    label="Entity"
                                    name="company_ids"
                                    rules={[
                                        { required: getFieldValue('role') !== user_role.ADMIN, message: 'Client id is required' },
                                        (form) => {
                                            if (form.getFieldValue('role') === 'ACCOUNTANT') {
                                                return ({
                                                    min: 1,
                                                    type: 'array',
                                                    message: 'Please assign at least one company to the user'
                                                })
                                            } else if (form.getFieldValue('role') === 'COMPANY') {
                                                return ({
                                                    max: 1,
                                                    type: 'array',
                                                    message: 'More than one company cannot be assigned to a user with Client role'
                                                })
                                            } else return {}
                                        }
                                    ]}
                                >
                                    <Select mode="multiple" disabled={getFieldValue('role') === user_role.ADMIN}>
                                        {
                                            all?.map(e => (
                                                <Option value={e._id} key={e._id}>{e.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        </Form.Item>
                    </Form>
            }
        </Modal>
    )
}