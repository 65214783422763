import { Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import Home from './pages/Home';
import AllQueries from './pages/AllQueries';
import SignIn from './pages/SignIn';
import Query from './pages/Query';
import Main from './components/layout/Main';
import { Loader } from './components/loader';
import { getAllUsers, login } from './store/action/user';

import 'antd/dist/antd.min.css';
import './assets/styles/main.css';
import './assets/styles/bootstrap.scss';
import './assets/styles/responsive.css';
import Page404 from './pages/404';
import LayoutAlt from './components/layout2/Main';
import Users from './pages/users';
import ChangePassword from './pages/changePassword';
import { user_role } from './consts';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ForgotPassword from './pages/forgotpassword';

function LayoutWrapper({ children, email }) {
	if (!email) return (<Navigate to='/sign-in' />)
	return (
		<Main>
			{children}
		</Main>
	);
}

function App() {

	const dispatch = useDispatch();
	const [cookie, setCookie] = useCookies(['userInfo']);
	const [loading, setLoading] = useState(true);
	let { email, role } = useSelector(st => st.user);

	useEffect(() => {
		if (cookie.userInfo?._id) {
			const getUserInfo = async () => {
				try {
					let res = await fetch(`${process.env.REACT_APP_API_URL}/user/${cookie.userInfo?._id}`, { credentials: 'include' });
					let status = res.status;
					res = await res.json();
					console.log(res);
					if (status === 200) {
						dispatch(login(res.response));
						console.log(cookie.userInfo.selectedCompany);
						setCookie('userInfo', {
							...res.response,
							selectedCompany: cookie.userInfo?.selectedCompany
						});
						dispatch(getAllUsers());
					}
				} catch (err) {
					console.log(err);
				} finally {
					setLoading(false);
				}
			};
			getUserInfo();
		} else {
			setLoading(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<React.Fragment>
			<div className="App">
				<Routes>
					<Route
						exact
						path="/"
						element={<LayoutWrapper email={email}><Home /></LayoutWrapper>}
					/>
					<Route
						exact
						path="/queries"
						element={<LayoutWrapper email={email}><AllQueries /></LayoutWrapper>}
					/>
					{
						(role === user_role.ADMIN) &&
						<Route
							exact
							path="/users"
							element={<LayoutWrapper email={email}><Users /></LayoutWrapper>}
						/>
					}
					<Route
						exact
						path="/query/:query_id"
						element={<LayoutWrapper email={email}><Query /></LayoutWrapper>}
					/>
					{/* <Route
						exact
						path="/check"
						element={<LayoutWrapper email={email}>< /></LayoutWrapper>}
					/> */}
					<Route
						exact
						path="/change_password"
						element={<LayoutWrapper email={email}><ChangePassword /></LayoutWrapper>}
					/>

					<Route path="/sign-in" exact element={<LayoutAlt>
						<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}>
							<SignIn />
						</GoogleReCaptchaProvider>
					</LayoutAlt>} />
					<Route path="/forgot-password" exact element={<LayoutAlt>
						<ForgotPassword />
					</LayoutAlt>} />
					<Route path="*" exact element={<LayoutAlt><Page404 /></LayoutAlt>} />

				</Routes>
			</div>
		</React.Fragment>
	);
}

class AppWrapper extends React.Component {
	componentDidCatch(err) {
		console.log(err);
	}

	render() {
		return <App />;
	}
}

export default AppWrapper;
