/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Row, Col, Dropdown, List, Typography, Space, Button, Popover, Breadcrumb, Input } from "antd";
import { BsChevronDown } from 'react-icons/bs';
import { BellOutlined, MenuOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import UserInfo from "../userInfo";

import { changeSelectedCompany, getAllCompanies, updateCompany } from "../../store/action/company";
import useFetch from "../../_fetch";
import { logout } from "../../store/action/user";
import { user_role } from "../../consts";
import Activity from "../activity";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Search_box } from "../Search_box/search_box";

function Header({ onPress }) {
	const { Title } = Typography;
	const path = window.location.pathname.split('/').filter((elem) => elem !== "").map((elem) => elem === "query" ? "queries" : elem)
	const { role, first_name, last_name, email } = useSelector(st => st.user);
	const { selected: selectedCompany, all } = useSelector(st => st.company);
	const [show,setShow] = useState()
	const [showFirmDropdown, setShowFirmDropdown] = useState(false);
	const [firmsToShow, setFirmsToShow] = useState(all);
	const placeholder = "Search Forms..."

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const _fetch = useFetch();
	const breakpoint = useBreakpoint();

	const [cookie, setCookie, removeCookie] = useCookies('userInfo')

	useEffect(() => window.scrollTo(0, 0));
	useEffect(() => { if (all.length) setFirmsToShow(all); }, [all]);

	async function _logout() {
		try {
			await _fetch(`${process.env.REACT_APP_API_URL}/user/login`, { method: 'DELETE' })
			dispatch(logout())
			removeCookie("userInfo");
			navigate('/sign-in', { replace: true });
		} catch (err) {
			console.log(err);
		}
	}

	const onSearch = (e) => {
		if (!e.target.value) setFirmsToShow(all);
		let filtered = all.filter(f => f.name.toLowerCase().includes(e.target.value))
		setFirmsToShow(filtered);
	};
	  useEffect(() => {
        for (var i = 0; i < all.length; i++){
        all[i].title = `${all[i].name}`
        
    }
    
	  }, [all])

	const menu = (
		<List
			min-width="100%"
			className="header-companies-dropdown"
			style={{ maxHeight: "70vh", overflow: "auto" }}
			itemLayout="horizontal"
			dataSource={show}
			header={<div className="input_box1">
				<Search_box all={all} setshow={setShow} className="move1" />
				{/* <Input
					placeholder="Search Firms..."
					onChange={onSearch}
				/> */}
			</div>}
			renderItem={(item) => (
				<List.Item
					className="pointer"
					onClick={() => {
						setCookie('userInfo', {
							...cookie.userInfo,
							selectedCompany: item
						});
						dispatch(changeSelectedCompany(item))
						navigate('/', { replace: true });
					}}
				>
					<List.Item.Meta
						title={item.name}
					/>
				</List.Item>
				

			)}
		/>
	);

	const accountMenu = (
		<List
			min-width="100%"
			className="header-companies-dropdown"
			itemLayout="horizontal"
			dataSource={all}
		>
			<List.Item
				className="pointer"
				onClick={() => navigate('/change_password')}>
				<List.Item.Meta
					title="Change Password"
				/>
			</List.Item>
			<List.Item
				className="pointer"
				onClick={() => _logout()}>
				<List.Item.Meta
					title="Logout"
				/>
			</List.Item>
		</List>
	);

	useEffect(() => {
		async function getCompanies() {
			try {
				let companies = await dispatch(getAllCompanies());
				if (companies) {
					if (cookie.userInfo.selectedCompany) {
						dispatch(updateCompany({
							...companies,
							selected: cookie.userInfo.selectedCompany
						}))
					} else {
						setCookie('userInfo', {
							...cookie.userInfo,
							selectedCompany: companies[0]
						});
						dispatch(updateCompany({
							...companies,
							selected: companies[0]
						}))
					}
				}
			} catch (e) {
				console.log(e)
			}
		}

		getCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [role]);

	return (
		<>
			<Row style={{ justifyContent: "space-between" }} gutter={[24, 0]}>
				{
					!breakpoint.lg &&
					<Col md={6}>
						<Button
							type="link"
							className="sidebar-toggler"
							onClick={() => onPress()}
						>
							<MenuOutlined style={{ fontSize: "20px" }} />
						</Button>
					</Col>
				}
				<Col className="d-flex align-items-center">
					{/* <div className="d-flex justify-content-start align-items-center"> */}
					{/* <Space> */}
					<Breadcrumb className="">
						<Breadcrumb.Item>
							<a onClick={() => navigate('/')} >Home</a>
						</Breadcrumb.Item>
						{path.map((crumb, index) => <Breadcrumb.Item>

							<a onClick={() => { if (path.length === index + 1) { } else navigate(-(index + 1)) }}>{crumb}</a>
						</Breadcrumb.Item>)}
					</Breadcrumb>
					{/* </Space> */}
					{/* </div> */}
				</Col>

				<Col lg={16} md={16}>


					<div className="d-flex justify-content-end align-items-center">
						<Space>


							<Popover
								trigger="click"
								content={
									<div style={{ height: "500px", width: "400px", overflow: "auto" }} id="scrollableDiv">
										<Activity canNavigate={true} />
									</div>
								}
								destroyTooltipOnHide
							>
								<Button
									style={{ borderRadius: "10px" }}
									type="primary"
									icon={<BellOutlined />}
									size="large"
								/>
							</Popover>
							{
								role !== user_role.COMPANY &&
								<Dropdown
									overlay={menu}
									trigger={["click"]}
									onVisibleChange={(f) => {
										setFirmsToShow(all);
										setShowFirmDropdown(f)
									}}
									destroyPopupOnHide
									visible={showFirmDropdown}
								>
									<Button
										className="my-auto"
										style={{ minWidth: "150px" }} type="primary"
									>
										<div className="d-flex justify-content-between">
											<Title level={4} className="my-auto text-white mx-1" >
												{selectedCompany.name}
											</Title>
											<BsChevronDown size={20} className="my-auto" />
										</div>
									</Button>
								</Dropdown>
							}
							<Dropdown overlay={accountMenu} trigger={["click"]}>
								<div className="pointer d-flex align-items-center">
									<UserInfo firstName={first_name} lastName={last_name} email={email}
										avatraProps={{
											size: 'large',
											style: { backgroundColor: '#1890ff' }
										}}
									/>
								</div>
							</Dropdown>
						</Space>

					</div>

				</Col>
			</Row>
		</>
	);
}

export default Header;
